import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import NewsletterSignup from "../components/newsletterSignup";
import { InlineWidget } from "react-calendly";

class Appointment extends React.Component {

  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Schedule an Appointment | Lewis Mohr Insurance Agency"
          meta={[
            {
              name: "description",
              content:
              "Want to schedule a call with one of our Baton Rouge based independent insurance agents? Schedule here!"
            }
          ]}
        />
        <Img
            alt="Abstact lighting"
            fluid={this.props.data.mainBg.childImageSharp.fluid}
            className="posts-bg"
          />
        <div className="site-body page-content">
          <h1>Schedule an Appointment</h1>
          <p>
          Want to schedule a call with one of our Baton Rouge based independent insurance agents? Schedule below using Calendly!
          </p>
          <div className="grid grid-gutters-lg">
            <div className="grid-cell u-full">
              <InlineWidget url="https://calendly.com/brittanymohrhunter/lewis-mohr-consultation?hide_event_type_details=1&hide_gdpr_banner=1" />
            </div>
          </div>
        </div>
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default Appointment;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "abstract-bg-1.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
  }
`;
